import { graphql, useStaticQuery } from 'gatsby';

import React from 'react';

const Footer = () => {
	const data = useStaticQuery(graphql`
		{
			docx(name: { eq: "en-footer" }) {
				id
				content
			}
		}
	`);

	const footer = data.docx?.content;

	if (footer) {
		return (
			<footer
				className="footer"
				dangerouslySetInnerHTML={{ __html: footer }}
			></footer>
		);
	} else {
		return null;
	}
};

export default Footer;
