import React, { useCallback, useRef, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Masonry from 'react-masonry-css';
import { SRLWrapper } from 'simple-react-lightbox';
import Sierotki from 'sierotki';
import lightBoxOptions from '../../options/lightBoxOptions'
import { Link } from "gatsby"

const authorFileName = 'edward_feliks_dziechdziarczyk';
const authorShortBioFileName = 'en-short';
const authorFullBioFileName = 'en-full';
const Author = () => {
	const data = useStaticQuery(graphql`
		query authorEnglishQuery {
			allFile(filter: { sourceInstanceName: { eq: "biography" } }) {
				edges {
					node {
						publicURL
						childDocx {
							content
						}
						name
					}
				}
			}
		}
	`);

	const docx = data.allFile.edges.filter(
		(edge) => edge.node.childDocx !== null
	);

	const photos = data.allFile.edges.filter(
		(edge) => edge.node.childDocx === null
	);

	const shortBiogram = docx.find(
		(document) => document.node.name === authorShortBioFileName
	).node.childDocx.content;

	const fullBiogram = docx.find(
		(document) => document.node.name === authorFullBioFileName
	).node.childDocx.content;

	const authorProfilePhoto = photos.find(
		(photo) => photo.node.name === authorFileName
	).node;

	const biographyPhotos = photos.filter(
		(photo) => photo.node.name !== authorFileName
	);

	const sortedBiographyPhotos = biographyPhotos.sort(function (a, b) {
		return parseInt(a.node.name, 10) - parseInt(b.node.name, 10);
	});

	const [isExpanded, setIsExpanded] = useState(false);

	const accordion = useRef(null);

	const toggleAccordion = useCallback(() => {
		setIsExpanded(!isExpanded);
		if (accordion.current.style.maxHeight) {
			accordion.current.style.maxHeight = null;
		} else {
			accordion.current.style.maxHeight =
				accordion.current.scrollHeight + 'px';
		}
	}, [isExpanded]);

	const fixOrphans = Sierotki.Sierotki;

	return (
		<div className="authorInfo">
			<div className="switchLang">
				<Link to="/" className="lang current">
					PL
				</Link>
				{" "}
				/
				{" "}
				<Link to="/en" className="lang">
					EN
				</Link>
			</div>
			<SRLWrapper options={lightBoxOptions}>
				<div className="authorShortBioGrid">
					<h1>Edward Feliks Dziechciarczyk</h1>
					<div className="authorShortBioImageWrapper">
						<img src={authorProfilePhoto.publicURL} alt="Edward Feliks Dziechciarczyk" />
					</div>
					<div
						className="authorShortBio"
						dangerouslySetInnerHTML={{
							__html: fixOrphans.orphansFix(shortBiogram),
						}}
					></div>
				</div>
				<div className="accordion" ref={accordion}>
					<div
						dangerouslySetInnerHTML={{
							__html: fixOrphans.orphansFix(fullBiogram),
						}}
					></div>
					<Masonry
						breakpointCols={{
							default: 3,
							1100: 3,
							700: 2,
							500: 1,
						}}
						className="my-masonry-grid biography-photos"
						columnClassName="my-masonry-grid_column"
					>
						{sortedBiographyPhotos.map((galleryImage, index) => {
							return (
								<div
									key={'gallery-image-' + index}
									className="galleryImageWrapper"
								>
									<img
										src={galleryImage.node.publicURL}
										alt=""
										loading="eager"
									/>
								</div>
							);
						})}
					</Masonry>
				</div>
			</SRLWrapper>
			<button onClick={() => toggleAccordion()}>
				<img
					alt=""
					src="/arrow.svg"
					className={
						!isExpanded
							? 'arrowReadMore'
							: 'arrowReadMore arrowReadMoreExpanded'
					}
				/>
			</button>
		</div>
	);
};

export default Author;
