import '../styles/styles.css';
import 'normalize.css';

import React  from 'react';

import SEO from '../components/SEO/SEO';
import { graphql } from 'gatsby';
import Obrazy from '../components/Obrazy/Obrazy';
import Author from '../components/Author/Author';
import Footer from '../components/Footer/Footer';

const Home = () => {
	return (
		<div className="padding">
			<div className="container">
				<SEO en/>
				<main>
					<Author/>
					<Obrazy/>
				</main>
				<Footer />
			</div>
		</div>
	);
};

export default Home;

export const query = graphql`
	{
		allFile(filter: { sourceInstanceName: { eq: "gallery" } }) {
			edges {
				node {
					id
					publicURL
					name
				}
			}
		}
	}
`;
